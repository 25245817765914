.admin-layout {
  .table {
    .money, .number {
      text-align: right !important;
    }
  }
  .compact-table>tbody>tr>td,
  .compact-table>tbody>tr>th,
  .compact-table>tfoot>tr>td,
  .compact-table>tfoot>tr>th,
  .compact-table>thead>tr>td,
  .compact-table>thead>tr>th {
    padding: 0.2em;
  }
}
