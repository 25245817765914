$dark-purple: #040311;
$purple: #161338;
$medium-purple: #5A53D4;
$light-purple: #897EF7;
$lighter-purple: #8E89CB;
$lighter-purple-2: #c5c0ff94;
$light-gray: #b3b3b3;
$lighter-gray: #AAAAAA;
$white: #ffffff;
$lavender: #A771FF; //#ABA5FC;
$red: #ff6060;
$yellow: #ffe600;
$charcoal: #28282D;
$light-charcoal: rgb(79, 79, 94);
$lighter-charcoal: rgba(255, 255, 255, 0.55);

$mint-green: #4FF4FF;

$bronze: #FF8933;
$silver: #D9D9D9;
$gold: #F5D75B;
$diamond: #5BF5D9;
$platinum: #38E7DC;
$master: #B300F2;

$gray: #A6A6A6;
$dark-gray: #343434;
$darkest-gray: #181818;
$input-label: #C9C9C9;

$gradient-platinum: linear-gradient(267.89deg, #5BF5D9 -14.18%, #38E7DC 85.78%);
$gradient-red: linear-gradient(79.12deg, #111111 69.48%, #FF6262 280.16%);
$gradient-green: linear-gradient(79.12deg, #111111 69.48%, #76FF60 280.16%);

$off-black: #111111;
$grey-border:  $dark-gray;

$off-black-lighter: $dark-gray;
$green: #008000;
$bright-green: #5EFF44;

$off-white: #FAFAFA;

$max-container-width: 676px;

$card-border-color: $grey-border;
$card-background-color: $off-black;

$input-border-color: $off-black-lighter;
$input-background-color: $black;

$input-color: $white;
$input-autofill-color: $white;
$form-group-label-color: $white;
$input-box-shadow-color: $black;

$input-placeholder-color: $light-gray;

$rectangle-green-line-color: rgba(118, 255, 96, 1);
$rectangle-red-line-color: rgba(255, 96, 96, 1);

$size-3: 0.1875rem;
$size-4: 0.25rem;
$size-5: 0.3125rem;
$size-6: 0.375rem;
$size-7: 0.4375rem;
$size-8: 0.5rem;
$size-9: 0.5625rem;
$size-10: 0.625rem;
$size-11: 0.6875rem;
$size-12: 0.75rem;
$size-13: 0.8125rem;
$size-14: 0.875rem;
$size-15: 0.9375rem;
$size-16: 1rem;
$size-17: 1.0625rem;
$size-18: 1.125rem;
$size-19: 1.1875rem;
$size-20: 1.25rem;
$size-21: 1.3125rem;
$size-22: 1.375rem;
$size-24: 1.5rem;
$size-27: 1.6875rem;
$size-28: 1.75rem;
$size-35: 2.1875rem;
$size-36: 2.25rem;
$size-37: 2.3125rem;
$size-44: 2.75rem;
$size-46: 2.875rem;

// 142b48
// CF0C1C
// 1F9D70
// 0B0920
// 458ff6
// 45f6a9
// 161338
// f3f3f3
// 746EC0
// 040212
// A6A4C7
