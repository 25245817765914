.admin-layout {

  & .form-group input, &.form-group input:focus, .form-group textarea {
    background-color: #ffffff;
    color: #000000;
    border-radius: 5px;
    border-color: #cecece;
  }

  .form-group label {
    font-style: normal;
    font-weight: 500;
    color: $black;
    padding-left: unset;
  }  

  & [class^="DropdownFilter_filter"] {
    background-color: $white;

    &:hover {
      background-color: $white;
    }
  }
  & .activation-combo-form {
    & .form-group select {
      float:right;
    }
    select.horizontal {
      padding:0;
      padding-left:1px;
      border:none;
      background-color: transparent;
      //width:100vw;
      height:30px;
      white-space: normal;

      & option {
        //height:100px;
        //width:100px;
        border:1px solid #000;
       // background-color:white;
        margin-left:-1px;
        display:inline-block;
      }
      option:checked{
        background: #7F52FF -webkit-linear-gradient(bottom,  #7F52FF 0%,  #7F52FF 100%);
        color: #fff;
      }
    }
    //select:has(option:checked[value!='']) {
    //  background: #7F52FF -webkit-linear-gradient(bottom,  #7F52FF 0%,  #7F52FF 100%);
    //  color: #fff;
    //}
  }
}
