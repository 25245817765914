.league {

  &-help {

    &-image {
      width: 9.75rem;
      height: 2.25rem;

      img {
        width: 100%;
        height: 100%;
      }
    }

  }

  &-rank-card {
    width: 14.875rem;
  }

  &-rank-image {
    width: 1.6875rem;
    height: 1.6875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;

    background-repeat: no-repeat;

    &.rank-1 {
      background-image: url("/images/icons/rank/1.svg");
    }

    &.rank-2 {
      background-image: url("/images/icons/rank/2.svg");
    }

    &.rank-3 {
      background-image: url("/images/icons/rank/3.svg");
    }

    &.rank-4 {
      background-image: url("/images/icons/rank/4.svg");
    }

    &.rank-5 {
      background-image: url("/images/icons/rank/5.svg");
    }

  }

  &-page {
    max-width: 28.125rem;
  }

  &-user-list {
    min-height: calc(100vh - 110px);
    &-avatar {
      width: $size-27;
      height: $size-27;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &-row {
      &-bronze {
        background: linear-gradient(266.89deg, rgba(255, 137, 51, 0.1) -5.26%, rgba(255, 161, 94, 0.1) 46.06%);
      }

      &-silver {
        background: linear-gradient(267.31deg, rgba(217, 217, 217, 0.1) -5.61%, rgba(174, 174, 174, 0.1) 106.09%);
      }

      &-gold {
        background: linear-gradient(267.89deg, rgba(245, 215, 91, 0.1) -14.18%, rgba(231, 171, 56, 0.1) 85.78%);
      }

      &-platinum {
        background: linear-gradient(267.89deg, rgba(91, 245, 217, 0.1) -14.18%, rgba(56, 231, 220, 0.1) 85.78%);
      }

      &-master {
        background: linear-gradient(267.89deg, rgba(205, 91, 245, 0.1) -14.18%, rgba(231, 56, 182, 0.1) 85.78%);
      }      
    }
  }

  &-tabs {

    display: flex;
    justify-content: flex-start;
    align-items: center;

    &-item {
      opacity: 0.50;
      border-radius: 50%;
      width: 2.5rem;
      height: 2.5rem;
      text-align: center;
      font-style: normal;
      font-size: $size-14;
      line-height: $size-17;
      display: flex;
      justify-content: center;
      align-items: center;

      &.active {
        opacity: 1.0;
        background-color: #181818;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .league {
    &-page {
      max-width: unset;
    }
  }
}