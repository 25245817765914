.make-prediction {
}

button.btn-make-prediction, .btn-make-trade {
  border: none;
  padding: 10px;
  color: #fff;
  font-weight: 700;
  background-color: $light-purple;
  font-size: $size-18;
  background: radial-gradient(76.37% 1209.82% at 82.18% 67.86%, #7F52FF 0%, #7B6DFF 68.75%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  border-radius: 2.6875rem;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  width: 13.3125rem;
  height: 4rem; 
  &-large {
    width: 350px;
  }
}

.btn-make-trade {
  background: linear-gradient(87.29deg, #392042 21.21%, rgba(82, 0, 255, 0.97) 208.81%);
}

.thumb {
  color: #746EC0;
  font-size: 1rem;
  font-weight: 900;
}

.like-dislike-share {
  background-color: #040212;
  height: 50px;

  &-count {
    font-size: 0.875rem;
    font-weight: normal;
    color: #ffffff;
  }
}

.prediction-detail {
  &-symbol {
    font-size: 1rem;
    font-weight: 700;
    color: $light-purple;
  }

  &-target-price {
    font-size: 1rem;
    font-weight: bold;
  }

  &-target-day {
    font-weight: normal;
    font-size: 0.75rem;
    font-family: Arial, Helvetica, sans-serif;
  }

  &-direction {
    font-size: 0.75rem;
    font-weight: normal;
  }
}

.prediction-description {
  textarea, textarea:focus {
    background-color: black;
    color: #ffffff;
    border-color: #343434;
  }
}


.make-prediction-form {
  // max-width: 650px;

  .tool-head {
    font-size: 1rem;
    font-weight: 900;
  }

  .tool-body {
    color: #a0a0a0;
    font-size: 0.875rem;
  }
}

.prediction-modal-body {
  min-height: 267px;
}
