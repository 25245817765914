.fixed-table-head {

  display: block;
  overflow-y: auto;
  max-height: calc(100vh - 240px);

  table {
    width: 100%;
    border-collapse: collapse;

    thead th {
      position: sticky;
      top: 0;
      background-color: #ffffff;
    }

    tbody {
      overflow-y: auto;
      max-height: calc(100vh - 220px);
    }

    tfoot {
      position: sticky;
      bottom: 0;
    }

  }
}

table {
  tbody tr.trade-group td {
    background-color: lightgrey;
  }
}

@media screen and (max-width: 920px) {
  .fixed-table-head {
    display: block;
    overflow-y: unset;
    max-height: unset;
  }
}