.filter {

  position: relative;
  z-index: 101;

  font-size: 1rem;

  &Selection {

    &:hover{
      background-color: #181818;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0.75rem;

    /* Auto layout */
    height: 40px;

    /* off-black */
    background: #111111;
    /* dark gray */
    border: 1px solid #343434;
    border-radius: 22px;

    padding: 1rem;

    &Expanded {
      border-radius: 1.375rem 1.375rem 0 0;
      border-bottom: #181818;
    
  }

  }

  &Selected {}


  &Items {
    position: absolute;
    background-color: #111111;
    padding: 0 1.0rem;
    width: 100%;

    &Expanded {
      border-radius: 0px 0px 1.375rem 1.375rem;
      border: 1px solid #343434;
      border-top: #181818;
    }

  }

  &Item {
    padding: 5px;
    margin: 5px 0;

    &:hover {
      background-color: #181818;
    }

    &Selected {
      background-color: adjust-color($color: #181818, $lightness: 10%);
      border-radius: 5px;
    }
  }
}

@media screen and (max-width: 576px) {

  .filter {

    font-size: 14px;
    
    &Selection {
      padding: 0.75rem;

      &Expanded {
      }

    }

    &Selected {}


    &Items {
      padding: 0 0.5rem 0.5rem 0.5rem;

      &Expanded {}

    }

    &Item {
      &Selected {}
    }
  }
}