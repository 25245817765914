.footer {
  border-top: solid 1px #818181;
  background-color: #131313;
  font-size: 0.75rem;
  line-height: 0.9075rem;
  font-weight: 400;

  &-logo {
    text-align: center;

    &-image {
      width: 130px;
      height: 34px;
      img {
        width: 100%;
      }
    }
  }

}