.admin-trades-list {
  font-size: $size-13;
}

.admin-trades-watchlist {
  background-color: $white;
  position: absolute;
  top: 50px;
  width: auto;
  right: 50px;
  font-size: 10px;
  z-index: 100;
  box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.3);
  padding: 1px;
}

.admin-exit-overlay {
  background-color: white;
  box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.3);
  padding: 5px;
  z-index: 100;
  position: absolute;
}

.admin-chart-overlay {
  width: 600px;
  height: 400px;
  background-color: white;
  box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.3);
  padding: 5px;
  z-index: 100;
  position: absolute;

}

@media screen and (max-width: 920px) {
  .admin-trades-list {
    font-size: $size-12;
  }
}