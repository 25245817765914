
.admin-layout .btn {

  font-size: 0.875rem;

  border-radius: 5px;

  &-small {
    font-size: 1rem;
  }

  &-common {
    cursor: pointer;

    &-start {
      width: 10px;
    }

    border: none;
    min-width: 110px;
    border-radius: 25px;
    height: 40px;
    color: #746EC0;
    background-color: #161338;

  }

  &-primary {
    // border-radius: 25px;
    // border-color: rgba(49, 45, 129, 0.5) !important;
    color: $white !important;
    // background-color: rgba(49, 45, 129, 0.5) !important;
  }

  &-secondary {
    color: $white;
    background-color: #808080;

  }


  &-float-make-prediction {
    position: fixed;
    bottom: .5rem;
    right: .5rem;
    z-index: 100;
  }

  &-symbol {

    background-color: $off-black;
    padding: 0.1rem 1rem;
    border-radius: 1.5625rem;

    a {
      text-decoration: none;
      color: #ffffff;
    }

    a:hover {
      text-decoration: underline;
    }

    &-active {
      background-color: #8F8F8F;
      color: #000000;
      font-weight: 700;
    }

    &-neutral {}
  }

  &-curved-side {
    border-radius: 1.5625rem;
  }

  &-light-purple,
  &-light-purple:hover {
    background-color: $light-purple;
  }  
}