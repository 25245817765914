.short-registration {
  &-title {
    color: #746EC0;
    font-weight: 700;
  }

  &-form {
    input {
      background-color: #000000;
      color: #ffffff;
      border-radius: 1rem;
      border-color: #211957;
    }

    input:focus {
      background-color: #000000;
      color: #ffffff;
    }
  }

  .btn-save {
    background-color: #211957;
    color: #ffffff;
    width: 7rem;
    font-weight: 700;
    border-radius: 1rem;
    padding: 5px;
    font-size: 1rem;
  }
}