.modal-doc {
  &-header {
    background-color: $charcoal;

    &-title {
      font-size: 1.25rem;
      font-weight: 700;
      color: $white;
    }
  }

  &-body {
    border-top-left-radius: unset !important;
    border-top-right-radius: unset !important;
  }
}