@mixin gradient-text {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.text-bronze {
  background: linear-gradient(266.89deg, #FF8933 -5.26%, #FFA15E 46.06%);
  @include gradient-text();
}

.text-silver {
  color: $white;
}

.text-gold {
  background: linear-gradient(267.89deg, #F5D75B -14.18%, #E7AB38 85.78%);
  @include gradient-text();
}

.text-platinum {
  background: linear-gradient(267.89deg, #5BF5D9 -14.18%, #38E7DC 85.78%);
  @include gradient-text();
}

.text-master {
  background: linear-gradient(267.89deg, #B300F2 -14.18%, #E738B6 85.78%);
  @include gradient-text();
}

.text-sunset {
  background: linear-gradient(143deg, #FF9C41 22.17%, #FF43B4 113.21%);
  @include gradient-text();
}

.text-green {
  color: #1F9D70;
}

.text-mint-green {
  color: $mint-green;
}

.text-red {
  color: #CF0C1C;
}

.text-bright-red {
  color: #FF6060;
}

.text-orange {
  color: #FF9C41;
}

.text-white {
  color: $white;
}

.text-bronze {
  color: $bronze;
}

.text-off-white {
  color: rgba(255, 255, 255, 0.8);
}

.text-purple {
  color: $purple;
}

.text-semi-bold {
  font-weight: 600;
}

.text-darkest-gray {
  color: $darkest-gray;
}

.text-charcoal {
  color: $charcoal;
}

.text-light-gray {
  color: $light-gray;
}

.text-gray {
  color:  $gray;
}

.text-lighter-gray {
  color: $lighter-gray;
}

.text-light-charcoal {
  color: $light-charcoal;
}

.text-medium-purple {
  color: $medium-purple;
}

.text-light-purple {
  color: $light-purple;
}

.text-lavender {
  color: $lavender !important;
}

.text-platinum {
  color: $platinum;
}
.text-lighter-charcoal {
  color: $lighter-charcoal;
}

.text-bright-green {
  color: $bright-green;
}

.text-underline-hover {
  text-decoration: none
}

.text-underline-hover:hover {
  text-decoration: underline;
}

.text-hover-lavender:hover {
  color: $lavender;
}

.text-hover-black {
  &:hover {
    color: #000000 !important;
  }
}

.text-smaller {
  font-size: calc(var(--bs-body-font-size) * 0.80) !important;
}

.text-larger {
  font-size: calc(var(--bs-body-font-size) * 1.10) !important;
}

.text-normal {
  font-size: calc(var(--bs-body-font-size) * 1) !important;
}

.text-130 {
  font-size: calc(var(--bs-body-font-size) * 1.3) !important;
}

.text-120 {
  font-size: calc(var(--bs-body-font-size) * 1.2) !important;
}
.text-110 {
  font-size: calc(var(--bs-body-font-size) * 1.1) !important;
}

.text-90 {
  font-size: calc(var(--bs-body-font-size) * 0.9) !important;
}

.text-80 {
  font-size: calc(var(--bs-body-font-size) * 0.8) !important;
}

.text-70 {
  font-size: calc(var(--bs-body-font-size) * 0.7) !important;
}

.text-60 {
  font-size: calc(var(--bs-body-font-size) * 0.6) !important;
}

.text-32 {font-size: 2rem}

.text-28 {
  font-size: 1.75rem !important;
}


.text-26 {
  font-size: 1.625rem !important;
}


.text-25 {
  font-size: 1.5625rem !important;
}

.text-24 {
  font-size: 1.5rem !important;
}

.text-22 {
  font-size: 1.375rem !important;
}

.text-21 {
  font-size: 1.3125rem !important;
}

.text-20 {
  font-size: 1.25rem !important;
}
.text-19 {
  font-size: 1.1875rem !important;
}
.text-18 {
  font-size: 1.125rem !important;
}
.text-17 {
  font-size: 1.0625rem !important;
}
.text-16 {
  font-size: 1rem !important;
}
.text-15 {
  font-size: 0.9375rem !important;
}
.text-14 {
  font-size: 0.875rem !important;
}
.text-13 {
  font-size: 0.8125rem !important;
}
.text-12 {
  font-size: 0.75rem !important;
}
.text-11 {
  font-size: 0.6875rem !important;
}
.text-10 {
  font-size: 0.625rem !important;
}


.text-sunset {
  background: linear-gradient(237.79deg, #FF9C41 22.17%, #FF43B4 113.21%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}