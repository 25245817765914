.js-plotly-plot .plotly .cursor-crosshair {
  cursor: default !important;
}

.cursor-pointer .js-plotly-plot .plotly .main-svg {
  pointer-events: all !important;
}

.predictor-card-chart .js-plotly-plot .plotly .main-svg {
  pointer-events: all !important;
}