.line-height-10 {
  height: $size-10;
}

.line-height-11 {
  height: $size-11;
}

.line-height-12 {
  height: $size-12;
}

.line-height-13 {
  height: $size-13;
}

.line-height-14 {
  height: $size-14;
}

.line-height-15 {
  height: $size-15;
}

.line-height-16 {
  height: $size-16;
}

.line-height-17 {
  height: $size-17;
}

.line-height-18 {
  height: $size-18;
}

.line-height-19 {
  height: $size-19;
}

.line-height-20 {
  height: $size-20;
}

.line-height-21 {
  height: $size-21;
}

.line-height-22 {
  height: $size-22;
}