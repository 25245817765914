
.apexcharts-tooltip {
  background: #f3f3f3;
  color: #161338;
}

.prediction-detail {
  font-size: 0.875rem;
  
  &-name, &-date, &-text {
    font-size: 0.75rem;
  }

  &-text {
    font-weight: 400;
    line-height: 0.9375rem;
    font-style: normal;
    color: #D5D5D5;
  }


  &-date {
    color: rgba(255, 255, 255, 0.35);
  }

  &-name {
    color: rgba(255, 255, 255, 0.55);
  }

  &-description {
    font-size: 0.875rem;
    color: #D4D4D4;
    font-weight: 400;
  }

  &-price {
    font-size: 0.875rem;
    font-weight: 700;
  }

  &-symbol {

    a {
      color: #ffffff;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  &-range {
    min-width: 160px;
    &-title {
      font-size: 12px;
      text-align: center;
      padding: 2px 0;
    }

    &-title-green {
      color: $rectangle-green-line-color;
    }

    &-title-red {
      color: $rectangle-red-line-color;
    }

    &-price-range {
      border-radius: 10px;
      background-color: #000;
      padding: 2px 5px;
    }
    &-by {
      font-size: 10px;
      text-align: center;
      color: #D5D5D5;
    }
  }
}

.dark-card {
  background-color: $off-black;
  border-radius: $size-15;
}