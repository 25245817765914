.notification {

  &-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    border-bottom: solid 1px #1F1F1F;
    margin: 10px 0;
  }
  
  &-image {
    width: 50px;
    height: 50px;
    margin: 10px 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &-type {
    flex-grow: 1;
  }

  &-from {
    flex-grow: 1;
  }

  &-elapsed {
    
  }

  &-dot {
    border-radius: 50%;
    width: 1.3125rem;
    height: 1.3125rem;
    background: radial-gradient(76.37% 1209.82% at 82.18% 67.86%, #7F52FF 0%, #7B6DFF 68.75%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: $size-14;
  }
}