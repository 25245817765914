html .admin-layout, body .admin-layout {
  font-size: 0.875rem;
}


.admin_layout {
  & .modal-body {
    border: unset;
    background: #fff;
    border-radius: unset;
  }
}


@import './buttons';
@import './common';
@import './forms';
@import './reports';
@import './table';
