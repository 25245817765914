.contest {
  &-item {

    &-image {
      width: 42px;
      height: 42px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    &-detail {
      &-name {
        min-width: 275px;
        font-size: 1.125rem;

        &-text {
          font-size: 0.875rem;
        }

        &-closed {
          color: #7B7B7B;
        }

      }
      &-time {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: rgba(255, 255, 255, 0.8);   
        
        &-remaining {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 28px;
          border: 1px solid #63FF60;
          border-radius: 27px;
          color: #63FF60;
          padding: 15px;
        }

        &-until-start {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 28px;
          border: 1px solid #7B7B7B;
          border-radius: 27px;
          color: #7B7B7B;
          padding: 15px;
        }
      }
    }
  }

  &-winner {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 15px 0px 0px;
    gap: 10px;
    min-width: 208px;
    height: 42px;
    background: linear-gradient(267.89deg, rgba(245, 215, 91, 0.7) -14.18%, rgba(231, 171, 56, 0.7) 85.78%);
    border-radius: 26px;

    font-weight: 700;
    font-size: 14px;
    line-height: 17px;

    color: #ffffff;

    &-image {
      width: 42px;
      height: 42px;
      padding: 3px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        padding: 2px;
      }
    }


  }

  &-rankings {
    max-width: 450px;
  }

  &-mesage {
    background: $off-black;
    border: 1px solid #343434;
    border-radius: 15px;
    padding: 1rem 1.5rem;

  }

  &-anon {

    background: $off-black;
    border: 1px solid #343434;
    border-radius: 15px;
    padding: 1rem 1.5rem;

    &-head {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px
    }

    &-body {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
    }
  }

  .btn {
    &-make-prediction {
      max-width: 336px;
      height: 52px;
      background: radial-gradient(76.37% 1209.82% at 82.18% 67.86%, #7F52FF 0%, #7B6DFF 68.75%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
      ;
      border-radius: 29px;
    }

    &-join-contest {
      background-color: #ffffff;
      border-radius: 2.6875rem;
      width: 185px;
      height: 46px;
      color: #0C0C0C;
      font-size: 0.875rem;
      line-height: 1.0625rem;
      font-weight: 700;
      border-radius: 43px;
    }

    &-leave-contest {
      background-color: #1A1A1A;
      border-radius: 43px;
      width: 185px;
      height: 32px;
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 1.0625rem;
    }
  }

  &-head {
    min-height: 222px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), radial-gradient(76.37% 1209.82% at 82.18% 67.86%, #7F52FF 0%, #7B6DFF 68.75%);

    &-logo {

      //max-width: 610px;
      &-image {
        width: 55px;
        height: 55px;

        img {
          width: 100%;
          border-radius: 50;
          object-fit: cover;
        }

      }
    }

    &-name {
      font-size: 1.125rem;
      font-weight: 700;
      line-height: 1.375rem;
    }

    &-description {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.0625rem;
    }
  }

  &-navigation {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

  &-user-rank {
    background: linear-gradient(267.89deg, rgba(245, 215, 91, 0.2) -14.18%, rgba(231, 171, 56, 0.2) 85.78%);
    border-radius: 20px;
    padding: 5px 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }


  &-details {

    &-symbol {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
    }

    &-rules,
    &-range {
      font-size: 0.875rem;
      line-height: 1.0625rem;
    }

    &-stats {
      display: flex;
      flex-direction: row;
      gap: 1rem;

      &-submissions,
      &-daysleft,
      &-prize,
      &-contestants {
        font-size: 0.75rem;
        line-height: 0.9375rem;
        display: flex;
        flex-direction: row;
        gap: 4px;
        justify-content: flex-start;
      }

    }


    &-notes {
      &-head {
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.375rem;
      }

      &-subhead {
        font-size: 0.875rem;
        line-height: 1.0625rem;
        font-weight: 500;
      }
    }

  }
}