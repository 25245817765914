@mixin fit-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}


.discount-promo {

  &-banner {
    height: 2.5rem;

    &-img {
      width: $size-20;
      img {
        @include fit-image();
      }
    }
  }

  &-headline {
    font-size: 2rem;
    font-weight: bold;
  }

  &-main {
    background: linear-gradient(180deg, #000000 35.52%, #7111D1 223.31%);
  }

  &-main-body {
    max-width: 78.125rem;
  }

  &-logo {
    width: 15.1875rem;
    height: 3.625rem;

    & img {
      @include fit-image();
    }
  }

  &-highlights {
    &-item {
      &-icon {
        width: 3.875rem;
        height: 3rem;

        img {
          @include fit-image();
        }
      }
    }
  }

  &-mobile-preview {
    &-image {
      max-width: 27.125rem;

      img {
        @include fit-image();
      }
    }
  }

  &-complex-algo {
    height: 32.75rem;
    background-image: url('/images/discount-promo/bg-graph.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;


    &-card {
      max-width: 35.375rem;
      background-color: #000000;
      border-radius: 0.625rem;
    }
  }

  &-receive-alerts {

    &-preview {
      width: 27.125rem;

      img {
        @include fit-image();
      }
    }
  }

  &-proven-results {
    &-table {
      & thead tr th {
        font-weight: normal;
        color: rgb(255 255 255 / 80%);
        padding: 0.3125rem;
      }

      & tbody tr td {
        padding: 0.3125rem;
        margin: 0.3125rem;
        font-size: 1.5625rem;
        font-weight: bold;
      }
    }

    &-text {
      max-width: 22.8125rem;
    }
  }

  &-reserve-discount {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }
  
  &-modal {
    &-chart {
      width: 15.125rem;
      height: 7.9375rem;

      img {
        @include fit-image();
      }
    }
  }

  &-supported-symbols {
    width: 18rem;

    &-symbol {
      width: 5rem;
      height: 1.875rem;
    }
  }

    &-signup {
      & input {
        border-color: #4e4e4e;
      }

      &-lock {
        max-width: 40rem;
      }
    }

}

@media screen and (max-width: 576px) {
  .discount-promo {

    &-main {
      background: unset !important;
    }
  
    &-headline {font-size: 1.375rem; text-align: center; font-weight: 900 !important;}

    & .sub-headline {
      font-size: 1rem;
      text-align: center;
    }

    &-proven-results {
      &-table {
        & tbody tr td {
          font-size: 1rem;
        }
      }
    }

    &-complex-algo {
      height:30rem;

      &-card {
      background-color: unset;
      border-radius: unset;
      }
    }

    &-logo {
      width: 8.6875rem;
      height: 2.125rem;
    }
  
  }
}