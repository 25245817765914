.modal {

  &-trades {
    & .modal-content {
      background-color: $black;

      & .modal-body {
        background-color: $black;
        border: none;
      }
    }
  }

  &-content {
    background-color: #0B091F;
  }
  
  &-body {
    padding: 0.5rem;
  }
  
  &-backdrop.show {
    opacity: 0.8 !important;
  }
  
  &-lg {
    max-width: 440px;
  }

  &-message {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.0625rem;
  }
}

