.user-signals {

  &-scroll {
    max-height: 10rem;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .signal-stat {

    min-width: 18rem;
    border-radius: $size-10;

    &-up {
      background-color: rgba(94, 255, 68, 0.1),
    }

    &-down {
      background-color: rgba(255, 96, 96, 0.1),
    }

    &-pill {
      border-radius: $size-17;
      &-up {
        background-color: rgba(94, 255, 68, 0.19);
      }
  
      &-down {
        background-color: rgba(255, 96, 96, 0.2);
      }
    }
  
  }

}

@media screen and (max-width: 576px) {
  .user-signals {
    min-width: unset;
  }
}