.landing-page-v4 {
  background-image: url("/images/bg-light.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.landing-v4 {

  & .register-btn-create-account-disabled {
    background-color: #897ef760 !important;
    color: #ffffff !important;
  }

  background-color: #ffffff;
  margin-top: 1.5625rem;
  color: #000000;
  max-width: 56.25rem;

  & .form-control .agree-terms {
    background: unset;
  }

  & .form-check-input:checked {
    background-color: #0d6efd !important;
  }

  &-btn-signup {
    width: 170px;
    height: 46px;
    background: radial-gradient(76.37% 1209.82% at 82.18% 67.86%, #7F52FF 0%, #7B6DFF 68.75%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    border-radius: 45px;
    color: #ffffff;

    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    border: none;

  }

  &-content {
    margin-top: 1.5rem;
  }

  &-text, &-section, &-logo {
    padding: 0 6.25rem;
    width: 100%;
  }

  &-section {
    margin-top: 2rem;
    padding-top: 2rem;    
    &-title {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      margin: 1rem 0;
    }

    &-subtitle {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 1rem;
    }

    &-image {
      width: 100%;
      // max-width: 390px;
      img {
        width: 100%;
      }
    }
  }

  &-logo {
    text-align: start;

    img {
      width: 135px;
    }
  }

  &-text {
    margin-top: 1rem;
    font-weight: 700;
  }

  &-form {
    min-width: 18.75rem;
    max-width: 24rem;
    box-sizing: border-box;

    & input, & input:focus {
      background-color: #EDEDED;
      border: none;
      color: #000000;
    }

  }

  & .landing-page {
    background-image: url("/images/bg-light.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;

    background-color: #ffffff;

    &-title,
    &-highlight {
      font-weight: 900;
      font-size: 2.6125rem;
      line-height: 3.375rem;
      font-style: normal;
      text-align: start;
    }

    &-title {
      color: #000000;
    }

    &-subtitle {
      margin-top: 1.875rem;
      font-weight: 600;
      // max-width: 37.1875rem;
      text-align: start;
      font-size: 1.1875rem;

      &-highlight {
        color: rgba(137, 126, 247, 1);
      }
    }


    &-highlight {
      color: rgba(137, 126, 247, 1);
    }
  }

  &-footer {
    
  }
}


@media screen and (max-width: 576px) {
  .landing-v4 {

    background-color: #ffffff;
    margin-top: 1rem;
    max-width: 56.25rem;
  
    &-content {
      margin-top: 1.5rem;
    }
  
    &-text, &-section, &-logo {
      padding: 0 2rem;
      width: 100%;
    }
  
    &-section {
      margin-top: 2rem;
      padding-top: 0.5rem;

      &-title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        margin: 0.5rem 0;
      }
  
      &-subtitle {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 1rem;
      }
  
      &-image {
        width: 100%;
        // max-width: 390px;
        img {
          width: 100%;
        }
      }
    }
  
  
    &-text {
      margin-top: 1rem;
      font-weight: 700;
    }
  
  
    & .landing-page {
      background-image: url("/images/bg-light.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top;
  
      background-color: #ffffff;
  
      &-title,
      &-highlight {
        font-style: normal;
        font-weight: 900;
        font-size: 26px;
        line-height: 31px;
        text-align: start;
      }
  
      &-subtitle {
        margin-top: 1.875rem;
        text-align: start;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;

      }
    }
  }
}


