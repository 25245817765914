.multi-stock-form {
  color: $purple;
  &-symbol {
    z-index: 10000;
    input {
      width: 75px;
    }
  }

  &-date {
    width: 140px;
    z-index: 10000;
    input {
      z-index: 10000;
      width: 140px;
    }
  }
}