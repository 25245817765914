
.admin-layout {
  background-color: #fff;

  & > * {
    color: black;
  }


  .hover {
    display: inline-block;
    position: relative;

    &:hover {
      .__no-hover {
        opacity: 0;
      }
    }

    &:hover {
      .__hover {
        opacity: 1;
      }
    }

    .__hover {
      position: absolute;
      top: 0;
      opacity: 0;
      display: inline-block;
      width: 200px;
      text-align: left;
      // max-width: 200px;
      background: #d2d7db;
      border: 1px solid #0B091F;
      padding: 2px;
    }

    .__no-hover {
      opacity: 1;
    }
  }
}

.admin-modal {

  .modal-content {
    background-color: #fff;
    color: #000;

    .modal-title {
      color: #000;
    }
  }
}
