.user-signal-settings {
  &-symbols {
    max-height: 400px;
    overflow-y: scroll;
    padding-right: 0.625rem;
  }

  &-symbols::-webkit-scrollbar {
    width: 0.1875rem;
  }

  &-symbols::-webkit-scrollbar-thumb {
    background: #888
  }



}