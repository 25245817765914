.border-primary {
  border: solid 1px $purple;
}

.border-rounded-15 {
  border-radius: 0.9375rem;
}

.border-rounded-21 {
  border-radius: 1.3125rem;
}


.border-color-gray {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.border-color-dark-gray {
  border-color: $dark-gray;
  border: solid 1px;
}

.border-color-darkest-gray {
  border-color: #181818 !important;

}

.border-radius-15 {
  border-radius: $size-15;
}

.border-radius-44 {
  border-radius: $size-44;
}

.border-color-lavender {
  border-color: $lavender !important;
}

.border-color-mint-green {
  border-color: $mint-green !important;
}