// .carousel-control-prev, .carousel-control-next {
//   display: none;
// }

.carousel-control {
  &-prev {
    left: -35px;
  }

  &-next {
    right: -35px;
  }
}

.carousel-indicators [data-bs-target] {
  width: $size-10;
  height: $size-10;
  border-radius: $size-10;
  border-top: 0;
  border-bottom: 0;
}


.help {

  width: 100%;
  padding: $size-5 $size-10;

  &-icon {
    width: $size-20;
    height: $size-20;
    background-color: $white;
    color: $black;
    border-radius: 100%;
    position: relative;
  }
  &-icon::before {
    content: "?";
    font-weight: 900;
    margin-left: $size-5;
    position: absolute;
    top: -2px;
    left: 1px;  }

  &-slide {

    &-title {
      font-size: 0.875rem;
      font-weight: 400;
    }

    &-detail {
      width: 100%;
      border: solid 1px #313131;
      background-color: #000000;
      border-radius: $size-15;
      padding: $size-10;
      font-size: $size-14;
    }

    &-image {
      width: 100%;
      min-height: 370px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &-items {
    padding-right: 5px;
    &-item {
      border-bottom: solid 1px #3E3E3E;
      padding-bottom: 16px;
    }
  }

}

.modal-help {
  &-prediction-type ul {
    padding-left: 1.25rem;
  }
}