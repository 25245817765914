
.stock-symbol {
  &-container {
    background-color: #000000;
    border-radius: 1.5625rem;
    padding: 1rem;
    margin: 0;
  }

  &-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;

    &-item {
      border-bottom: 1px solid rgba(137, 126, 247, 0.42);
      padding: 0.5rem;
    }

    &-item-selected {
      font-weight: 900;
      background:  rgba(189, 185, 236, 0.42);
    }

  }


}