.share-component {
  position: relative;
}

.share-menu {
  position: absolute;
  font-size: 1.125rem;
  margin: 0 auto;
  top: -7rem;
  right: 0;
  width: 9.375rem;
  padding: 0.625rem;
  z-index: 1000;
  border-radius: 0.9375rem;
  background: #343434;

  &-bottom {
    top: 1.2rem;
  }
}

.share-rounded {
  width: 2.25rem;
  height: 2.25rem;
  //padding-left: 0.5rem;
  flex-shrink: 0;
}