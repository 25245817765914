.followee {
  &-card {

    width: auto;
    max-width: $max-container-width;
    border-radius: 0.3125rem;
    box-sizing: border-box;
    border: solid 1px #0B091F;
    background-color: #0B091F;    
    padding: 1rem;

    &-nickname {

    }
    
    &-image {
      // min-width: 4.6875rem;
      // min-height: 4.6875rem;
      // max-height: 6.25rem;
      // max-width: 6.25rem;
      width: 6.25rem;
      height: 6.25rem;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
}