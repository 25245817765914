.register {

  &-email {
    max-width: 380px;
    margin: 1rem auto;
    background-color: $dark-purple;
    padding: 10px;
  }
  
  &-message {
    &-title {
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 10px;
    }
  
    &-subtitle {
      font-size: 14px;
      font-weight: 500;
    }
  }

  &-label {
    color: rgba(201, 201, 201, 1) !important;
    font-weight: 500 !important;
    line-height: 15px !important;
    font-size: 12px !important;
  }

  &-action, &-link {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
  }
  &-action {
    color: #ffffff;
  }

  &-link {
    color: rgba(137, 126, 247, 1);
    text-decoration: underline;
  }

  &-btn-create-account {
    height: 54px;
    &-disabled {
      background-color: #ffffff !important;
      color: #000000 !important;
    }
  }

}


.set-password-form, .reset-password-form {
  max-width: 380px;
}


