.navigation {
  min-width: 260px;
  background-color: #000000;

  &-mobile {
    position: sticky;
    top: 0; left: 0;
    background: #000000;
    width: 100%;
    z-index: 100;
    border-bottom: solid 1px #000000 !important;
    height: 50px;
    //padding: 0 1rem;

    a {
      font-size: 1.25rem;
    }
  }

  &-profile-thumb {
    width: 30px;

    img {
      width: 100%;
      border-radius: 50%;
    }
  }

  &-links {
    color: #000000;
    font-size: 16px;

    a {
      text-decoration: none;
      color: #ffffff;
    }

    a:hover {
      text-decoration: underline;
    }

    &-image {
      width: 22px;
      height: 22px;
      margin-bottom: 10px;
      margin-left: 10px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

  }

  &-link {
    font-size: 1.125rem;
    background-color: #000000;
    color: #ffffff;
    padding: 0 10px;
    border-radius: 33px;
    height: 50px;
    font-weight: 700;
    display: flex;
    width: 203px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &-on {
      color: #000000;
      background-color: #ffffff;
    }
  }

  &-link-mobile {
    border-radius: 50%;
    height: unset;
    padding: unset;
    width: unset;

    &-image {
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
        
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    &-on {
      color: #000000;
      background-color: #ffffff;
    }    

  }


  &-collapsed {
    display: none;
  }

  &-expanded {
    display: block;
  }
}

.top-nav {
  width: 100%;
  padding: 0.625rem 0;
  background-color: #000000;
  z-index: 1000;
  top: -12px;

  &-sticky {
    position: sticky;
    top: 0px;
    transition: top 300ms ease-in;

  }

}



.mobile-nav-spacer {
  height: 3rem;
  background-color: #0B091F;
}

@media screen and (max-width: 576px) {
  .navigation {
    min-width: 50px;
  }
}


@media screen and (max-width: 1000px) {
  .navigation {
    min-width: 50px;

    &-collapsed {
      display: block !important;
      position: sticky;
      top: 0;
      z-index: 2;
    }

    &-expanded {
      display: none !important;
    }
  }


  .top-nav {
  
    &-sticky {
      top: 50px;
      z-index: 1;
    }
  
  }
  
}