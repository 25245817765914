@import '../common/chart-variables.module';


.shimmer {
  color: #ffffff;
  display: inline-block;
  mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
}

@keyframes shimmer {
  100% {-webkit-mask-position:left}
}

.prediction-tool {
  position: relative;

  &-stock-detail {
    max-width: 17.1875rem;
  }

  &-wrapper {}

  &-box {
    width: 50px;
    height: 100%;

    &-color {
      position: relative;
      margin: 0 auto;
      width: 13px;
      height: 100%;
      border-radius: 28px;
      background-image: linear-gradient(180deg, rgba(17, 106, 76, 0.5) 25%, rgba(147, 33, 42, 0.5) 75%);

    }
  }

  &-tip {
    position: absolute;
    top: 0px;
    right: 50px;
    color: #ffffff;
    min-width: 275px;
    z-index: 3;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.9);
    font-weight: 700;
    box-shadow: 0 0 7px #ffffff;
    border-radius: 5px;
    
    &-text {
    }

    &-arrow {
      width: 30px;
      object-fit: contain;
    }

  }

  // &-color {

  //   &-green {
  //     .prediction-tool-price {
  //       // color: rgba(25, 159, 126, 1);
  //     }
  //   }

  //   &-red {
  //     .prediction-tool-price {
  //       // color: rgba(159, 25, 25, 1);
  //     }
  //   }
  // }

  &-select {
    width: 50px;
    position: absolute;
    // top: 0;
    color: #0B091F;
    background: rgba(217, 217, 217, 0.2);
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 2px;
    left: -19px;
  }

  &-lines {
    :first-child {
      margin-top: -4px;
    }

    :last-child {
      margin-top: 4px
    }

    &-line {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      width: 12px;
      height: 1px;
      opacity: 0.7;
      background: #FBFBFB;
      // border-top: 1px solid #FBFBFB;
    }
  }

  &-arrow {
    opacity: 0.5;
    border: solid #FFFFFF;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 20px;
    position: absolute;

    &-up {
      top: -12px;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }

    &-down {
      bottom: -12px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }

  $rectangle-red-color: rgba(255, 96, 96, 0.7);
  $rectangle-green-color: rgba(99, 255, 96, 0.7);
  $rectangle-gray-color: rgba(134, 134, 134, 0.7);

  &-price {
    position: absolute;
    height: 20px;
    // border: 1px #fff solid;
    background: rgba(11, 9, 31, 0.7);
    color: #fff;
    font-size: 13px;
    font-weight: 700;

    &-exact {
      // top: 7px;
    }

    &-high {
      top: -10px;
    }

    &-low {
      bottom: -10px;
    }

    &-color {
      font-weight: bold;

      &-green {
        color: rgba(99, 255, 96, 1);
      }

      &-red {
        // color: $rectangle-red-color;
        color: rgba(255, 96, 96, 1);
      }
    }
  }

  &-overlay {

    &-instructions {
      position: absolute;
      top: 0px;
      background: #000000;
      border: 1px solid rgb(255 255 255 / 62%);
      z-index: 100;
      padding: 5px 0;
      opacity: 0.8;
      font-size: 0.6875rem;
    }

    .red-dash-line {
      background: linear-gradient(to right, $rectangle-red-color 0%, $rectangle-red-color 50%, transparent 50%, transparent 100%) repeat-x left bottom,
        linear-gradient(to right, $rectangle-red-color 0%, $rectangle-red-color 50%, transparent 50%, transparent 100%) repeat-x left top;
      background-size: 19px 2px;
    }

    .red-dash-line-center {
      background: linear-gradient(to right, $rectangle-red-color 0%, $rectangle-red-color 50%, transparent 50%, transparent 100%) repeat-x left center;
      background-size: 19px 2px;
    }

    .red-gradient {
      background: linear-gradient(180deg, rgba(38, 40, 40, 0.4) 0%, rgb(53, 14, 14, 0.5) 100%);
      // opacity: 0.5;
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }

    .red-gradient-dashed-top {
      background: linear-gradient(to top,rgba(38, 40, 40, 0.4) 0%, rgb(53, 14, 14, 0.5) 100%);
      border-top: 2px dashed $rectangle-red-color;
    }

    .gray-gradient-dashed-top {
      background: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, rgba(38, 40, 40, 0.4) 100%);
      border-top: 2px dashed $rectangle-gray-color;
    }

    .green-dash-line {
      background: linear-gradient(to right, $rectangle-green-color 0%, $rectangle-green-color 50%, transparent 50%, transparent 100%) repeat-x left bottom,
        linear-gradient(to right, $rectangle-green-color 0%, $rectangle-green-color 50%, transparent 50%, transparent 100%) repeat-x left top;
      background-size: 19px 2px;
    }

    .green-dash-line-center {
      background: linear-gradient(to right, $rectangle-green-color 0%, $rectangle-green-color 50%, transparent 50%, transparent 100%) repeat-x left center;
      background-size: 19px 2px;
    }

    .green-gradient {
      background: linear-gradient(180deg, rgba(38, 40, 40, 0.4) 0%, rgba(14, 53, 47, 0.5) 100%);
      // opacity: 0.5;
      transform: rotate(-180deg);
    }

    .green-gradient-dashed-bottom {
      background: linear-gradient(to bottom, rgba(38, 40, 40, 0.4) 0%, rgba(14, 53, 47, 0.5) 100%);
      border-bottom: 2px dashed $rectangle-green-color;
    }

    .gray-gradient-dashed-bottom {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(38, 40, 40, 0.4) 100%);
      border-bottom: 2px dashed $rectangle-gray-color;
    }



    .gray-dash-line {
      // background: linear-gradient(to right, $rectangle-gray-color 0%, $rectangle-gray-color 50%, transparent 50%, transparent 100%) repeat-x left bottom,
      //   linear-gradient(to right, $rectangle-gray-color 0%, $rectangle-gray-color 50%, transparent 50%, transparent 100%) repeat-x left top;
      background-size: 19px 2px;
    }

    .gray-dash-line-center {
      background: none; //linear-gradient(to right, $rectangle-gray-color 0%, $rectangle-gray-color 50%, transparent 50%, transparent 100%) repeat-x left center;
      background-size: 19px 2px;
    }

    .gray-gradient {
      background: $rectangle-gray-color; //linear-gradient(180deg, rgba(38, 40, 40, 0.4) 0%, rgb(106 106 106) 100%);
      opacity: 0.5;
      transform: rotate(-180deg);
    }

    width: 100%;
    position: absolute;

    &-range {
      width: 100%;
      height: 100%;

      &-background {
        width: 100%;
        height: 100%;
      }

      &-red {
        @extend .red-dash-line;

        div {
          @extend .red-gradient;
        }
      }

      &-green {
        @extend .green-dash-line;

        div {
          @extend .green-gradient;
        }
      }

      &-gray {
        @extend .gray-dash-line;

        div {
          @extend .gray-gradient;
        }
      }


    }

    &-line {
      width: 100%;
      height: 100%;

      &-red {
        @extend .red-dash-line-center;
      }

      &-green {
        @extend .green-dash-line-center;
      }

      &-gray {
        @extend .gray-dash-line-center;
      }

    }
  }

}