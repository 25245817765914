/* walkthrough is expected to be modal */
.walkthrough {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  &-content {
    width: 18.75rem;
    position: fixed; 
    z-index: 110;
  }

  &-container {
    background: $off-black;
    border-radius: 15px;
    border: 1px solid #343434;
  }

  &-head {
    padding: $size-10 $size-15;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }

  &-body {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  &-title {
  }
}

