.badge {
  width: 2.5rem;
  height: 3.125rem;

  &-content {
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-footer {
    height: 20%;
    opacity: 0.60;
    font-size: $size-11;
  }

  &-black-hover:hover {
    background-color: $off-black;
  }

  & .streak-count {
    width: 2rem;
    height: 2rem;
    padding: 3px;
    border-radius: 50%;
    position: absolute;
    right: -10px;
    bottom: -15px;
  }


}
