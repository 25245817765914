.user-profile {
  max-width: 650px;

  &-phone-number {
    max-width: 170px;
  }

}

a.predictor-link {
  color: #ffffff;
  text-decoration: none;
}

a.predictor-link:hover {
  text-decoration: underline;
}


div.user-profile {
  label {
    color: $input-label !important;
  }

  input, input:focus {
    border-radius: 1.5625rem;
    background-color: black;
    color: #ffffff;
  }

  textarea, textarea:focus {
    background-color: black;
    color: #ffffff;
  }
}


.profile-team {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: $size-8;
  height: $size-37;
  background: #343434;
  border-radius: $size-46;

  &-logo {
    width: $size-19;
    height: $size-27;

    img {
      width: 100%;
      height: 100%;
      max-width: $size-19;
      max-height: $size-24;
    }
  }

  &-name {
    font-weight: 600;
    font-size: $size-12;
    line-height: $size-15;
  }
}

.profile-profit {
  height: 2.125rem;
  &-image {
    width: 2.125rem;
    height: 2.125rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}

.profile-ticket {
  img {
    width: $size-35;
    height: $size-35;
    object-fit: contain;
  }
}