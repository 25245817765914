.alternate-card {
}
.alternate-card .predictor-card {
  border-radius: unset;
  border: none;
  background-color: $black;
}

.predictor {

  & &-accuracy {
    min-width: unset;
    border-radius: unset;
    padding: unset;
  }

  &-accuracy, &-profitability {
    min-width: 143px;
    padding: 1rem;
    border-radius: 15px;
  }

  &-accuracy {
    background: $off-black;
  }

  &-profitability {
    background: linear-gradient(79.12deg, #111111 69.48%, #76FF60 280.16%);
  }

  &-award {
    img {
      width: 23px;
      height: 23px;
    }
  }

  &-team {
    img {
      width: 16px;
      height: 20px;
    }
  }

  &-at {
    color: #A4A4A4;
    font-size: 0.875rem;

    a {
      color: #A4A4A4;
      text-decoration: none;
    }
  }

  &-thumb {
    width: 43px;
    height: 43px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      max-width: 43px;
    }
  }

  &-name {
    font-weight: 500;
    font-size: 0.875rem;

    a {
      color: #ffffff;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  &-accuracy {
    font-size: 16px;
  }
}

.predictor-card {

  &.top {
    min-width: 189px;
    width: 189px;
    height: 94px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 12px;
    gap: 12px;
    border: solid 1px #343434;
    border-radius: 0.9375rem;

    & > div {
      & > .avatar {
        width: 2.375rem;
        height: 2.375rem;
        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      & > .username {
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 126px;
        white-space: nowrap;

        & .twitter-name {
          font-size: $size-12;
          color: #A6A6A6;
        }

        & a {
          font-weight: 700;
        }
      }

    }

  }


  width: auto;
  // max-width: 600px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0.9375rem;
  border-bottom-right-radius: 0.9375rem;
  box-sizing: border-box;
  border: solid 1px $card-border-color;
  border-top: solid 1px $off-black;
  background-color: $card-background-color;

  &-chart {
    min-height: 250px;
    min-width: 270px;
    width: 100%;

    &-hit,
    &-miss {
      width: 22px;
    }

    &-hit {
      background-color: #5A53D4;
      color: $black;
    }


  }

  &-price {
    border: solid 1px;
    border-color: white;
    padding: 5px;
    border-radius: 10px;

    &-title {
      font-size: 12px;
    }

    width: 120px;
    text-align: center;
  }

  &-price-closing {
    background-color: #458ff6;
  }

  &-price-prediction {
    background-color: #45f6a9;
    color: $purple;
  }

  &-time-left {
    font-size: 0.80rem;
    color: #A4A4A4;
  }

  &-head {
    position: sticky;
    top: 0;
    height: 3rem;
    background: #000000;
    width: 100%;
    z-index: 1000;
    max-width: 715px;

    &-nickname {
      font-size: 1.2rem;
      font-weight: 700;
    }


    &-accuracy {
      font-weight: 200;
    }

    &-count {
      font-weight: 200;

    }
  }

  &-body {
    margin-top: 0.625rem;

    &-nickname {
      font-size: 1.2rem;
      font-weight: 700;
    }

    &-at {
      font-size: 0.875rem;
      color: #A4A4A4;
    }

    &-about-me {
      font-size: 1.0rem;
    }
  }

}

.predictor-tabs {
  &-tab {
    border-bottom: solid 1px;
    font-weight: 200;

    &-active {
      color: #ACA4FF;
      font-weight: 900;
      border-bottom: solid 1px #ACA4FF;
    }
    
  }


}

@media screen and (max-width: 576px) {
  .predictor {
    &-at {
      max-width: 70px;
    }

    &-name {
      max-width: 150px;
    }

    &-at, &-name {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-card {
      &-head {
        top: 50px;
        height: 3rem;
        position: sticky;

        &-nickname {
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 160px;
          white-space: nowrap;          
        }
      }

      &-body {
        margin-top: 1.4375rem;
      }
    }
  }
}