.search {
  display: flex;
  justify-content: flex-start;
  // align-items: center;
  height: 4.125rem;
  width: 100%;

  &-start {
  }

  &-body {
    width: 100%;
  }

  &-stock {
    max-height: 23.125rem;
    overflow: auto;
  }

  &-text {
    border: none;
    color: #A6A4C7;
    width: 100%;
    padding: 0.625rem 0 !important;
    border-radius: unset !important;
    background-color: $off-black !important;
    &:hover, &:focus {
      background-color: $darkest-gray !important;
    }
  } 

  &-text:focus, &-text:focus-visible, &-text:focus-within {
    border: none;
    outline: none;
  }

  &-end {
  }

  &-container:focus-within, &-container:hover .search-text {
    background-color: $darkest-gray !important;
  }
  // &-container {
  //   border: solid 1px #343434; 
  //   max-width: 400px;
  //   padding: 0.4rem 0.5rem;
  //   border-radius: 30px;
  // }

  &-form {
    position: relative;
    &:hover {
      background-color: $darkest-gray;
    }

  }

  

  &-suggestions {
    position: absolute;
    top: 60px;
    left: 50px;
    border: solid 1px #343434;
    border-radius: 5px;
    background-color: black;
    min-width: 200px;
    color: $lighter-charcoal;
    padding: 10px;
    max-height: 200px;
    overflow: auto;
    cursor: pointer;
    width: 100%;
    max-width: 345px;
    z-index: 1000;

    & div:last-child .user-search-result {
      border-bottom: none;
      padding-bottom: unset;
    }
  }

}

@media screen and (max-width: 576px) {
  .search {
    height: 3.125rem;
  }
}