.infeed {
  &-card {
    min-height: 250px;
    border: solid 1px $black;
    border-radius: $size-15;
    transition: 0.3s;
    
    &:hover {
      border-color: $lavender;
      background: none !important;
    }

    &-dashboard-chart {
      transition: 0.5s;
      cursor: pointer;
      background-color: $black;

      &:hover {
        background-color: $darkest-gray;
      }
    }

    &.lavender-gradient {
      background: linear-gradient(265.18deg, rgba(167, 113, 255, 0.09) -28.61%, #994486 156.8%);
    }

    &.purple-gradient {
      background: linear-gradient(265.18deg, rgba(167, 113, 255, 0.09) -28.61%, #644499 156.8%);
    }
   
    &.rank-1-gradient {
      background: linear-gradient(266.89deg, rgba(255, 137, 51, 0.1) -5.26%, rgba(255, 161, 94, 0.1) 46.06%);
      &:hover {
        border-color: $bronze;

      }
    }
  
    &.rank-2-gradient {
      background: linear-gradient(267.31deg, rgba(217, 217, 217, 0.1) -5.61%, rgba(174, 174, 174, 0.1) 106.09%);
      &:hover {
        border-color: linear-gradient(266.89deg, rgba(255, 137, 51, 0.1) -5.26%, rgba(255, 161, 94, 0.1) 46.06%);
        border-color: $silver;
      }
    }
  
    &.rank-3-gradient {
      background: linear-gradient(267.89deg, rgba(245, 215, 91, 0.1) -14.18%, rgba(231, 171, 56, 0.1) 85.78%);
      &:hover {
        border-color: linear-gradient(266.89deg, rgba(255, 137, 51, 0.1) -5.26%, rgba(255, 161, 94, 0.1) 46.06%);
        border-color: $gold;
      }
    }
  
    &.rank-4-gradient {
      background: linear-gradient(267.89deg, rgba(91, 245, 217, 0.1) -14.18%, rgba(56, 231, 220, 0.1) 85.78%);
      &:hover {
        border-color: linear-gradient(266.89deg, rgba(255, 137, 51, 0.1) -5.26%, rgba(255, 161, 94, 0.1) 46.06%);
        border-color: $diamond;
      }
    }
  
    &.rank-5-gradient {
      background: linear-gradient(267.89deg, rgba(205, 91, 245, 0.1) -14.18%, rgba(231, 56, 182, 0.1) 85.78%);
      &:hover {
        border-color: linear-gradient(266.89deg, rgba(255, 137, 51, 0.1) -5.26%, rgba(255, 161, 94, 0.1) 46.06%);
        border-color: $master;
      }
    }
  
  }




}