.alternate-card .result {
  border: none;
  border-radius: unset;
}

.result {
  height: 25px;
  border: 1px solid $card-border-color;
  border-bottom: none;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-weight: 700;
  font-size: 0.875rem;

  &-active {
    background-color: $off-black;
    height: 5px; 
  }

  &-whoops {
    background: linear-gradient(185.65deg, rgba(171, 165, 252, 0.67) -323.49%, rgba(77, 65, 242, 0) 304.44%);
  }

  &-near-miss {
    background: linear-gradient(185.65deg, rgba(171, 165, 252, 0.67) -323.49%, rgba(77, 65, 242, 0) 304.44%);
  }

  &-not-bad {
    background: linear-gradient(185.65deg, #727272 -323.49%, rgba(255, 255, 255, 0) 304.44%);
  }

  &-good-call {
    background: linear-gradient(185.65deg, #19AE1F -323.49%, rgba(146, 253, 151, 0) 304.44%);
  }

  &-impressive {
    background: linear-gradient(185.65deg, #1527CA -323.49%, rgba(153, 163, 249, 0) 304.44%);
  }

  &-wow {
    background: linear-gradient(185.65deg, #FF7A00 -323.43%, #FC8F2B -128.11%, rgba(255, 92, 0, 0) 304.44%);
  }

  &-bullseye {
    background: linear-gradient(185.65deg, #7101FF -323.49%, rgba(242, 65, 224, 0) 304.44%);
  }
}
