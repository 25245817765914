.apexcharts {

  &-menu {
    background-color: #000000 !important;

    &-item {
      &:hover {
        background-color: #142b48 !important;
      }
    }
  }

}