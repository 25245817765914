.leaderboard {

  .btn-contest {
    width: 154px;
    height: 35px;
    background: linear-gradient(237.79deg, #FF9C41 22.17%, #FF43B4 113.21%);
    border-radius: 41px;

    display: flex;
    flex-direction: row;
    justify-content: center;

    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: $off-black;
  }

  &-head-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
  }


  & .team-ranked-list .team-roster-item-image {

    width: 27px;
    height: 33px;

    img {
      border-radius: unset;
      object-fit: cover;
      height: 100%;
    }
  }

  & .team-contest-overview-container {
    border: 1px solid #FF9C41;
    // border-image-source: linear-gradient(237.79deg, #FF9C41 22.17%, #FF43B4 113.21%);
    background: $off-black;
    border-radius: 15px;
    max-width: 367px;
    padding: 1.25rem;
  }

  &-card {
    &-item {
      font-weight: 700;
      height: 2.6875rem;
      border-radius: 1.375rem;

      &:nth-child(odd) {
        background: linear-gradient(267.89deg, rgba(245, 215, 91, 0.1) -14.18%, rgba(231, 171, 56, 0.1) 85.78%);
      }

      &:nth-child(even) {
        background: linear-gradient(267.31deg, rgba(217, 217, 217, 0.1) -5.61%, rgba(174, 174, 174, 0.1) 106.09%);
      }

      & .team-roster-item-name, &.team-roster-item-accuracy {
        font-weight: 700;
      }
    }
  }

}


@media screen and (max-width: 576px) {
  .leaderboard {
    & .team-ranked-list .team-roster-item {
      min-height: 60px;
    }

    & .btn-tabs {
      font-size: 13px;
    }

    & .team-contest-overview-container {
      max-width: unset;
    }

  }
}