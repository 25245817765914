.landing-v2 {
  margin-top: 1.5625rem;
  flex-grow: 1;

  &-content {
    max-width: 38rem;
  }

  &-logo {
    text-align: center;
    img {
      width: 135px;
    }

  }

  &-text {
    margin-top: 1rem;
    font-weight: 700;
  }

  &-form {
    border: 1px solid #6455FF;
    min-width: 18.75rem;
    max-width: 24rem;
    margin-top: 2.625rem;
    box-sizing: border-box;
    background: $off-black;
    border-radius: 15px;
  }
  
  & .landing-page {

    &-title, &-highlight {
      font-weight: 900;
      font-size: 2.8125rem;
      line-height: 3.375rem;
      font-style: normal;
      text-align: center;
    }

    &-title {
      color: #FFFFFF;
    }

    &-subtitle {
      margin-top: 1.875rem;
      font-weight: 600;
      // max-width: 37.1875rem;
      text-align: center;
      font-size: 1.1875rem;
    }

    &-highlight {
      color: rgba(137, 126, 247, 1);
    }
  }

}

