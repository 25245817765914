@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import '~bootstrap/scss/bootstrap';
@import './scss/common/all';
@import './scss/components/all';
@import './scss/layouts/all';

html, body {
  font-family: 'Inter', sans-serif;
  background-color: #000000;
  color: #ffffff;
}

.modal-body {
  border: 1px solid #3E3E3E;
  background: #111111;
  border-radius: 0.5rem ;
}

.cursor-pointer {
  cursor: pointer;
}

body.noscroll {
  overflow: hidden;
}

// body::-webkit-scrollbar {
//   width: 12px;
// }

// body::-webkit-scrollbar-track {
//   background: #f1f1f1;
// }

// body::-webkit-scrollbar-thumb {
//   background: #888;
//   border-radius: 6px;
// }
