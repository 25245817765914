.btn {

  &-underline-hover {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      color: $gray;
    }
  }

  &-all-stats {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 20.3125rem;
    border: solid 1px $dark-gray;
    border-radius: 2.6875rem;
    font-weight: bold;
    padding: 0.3125rem 0;

    &:hover {
      color: black;
      border: solid 1px $white;
      background-color: $white;
    }
  }

  font-size: 1.125rem;
  border-radius: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;

  &-small {
    font-size: 1rem;
  }

  &-common {
    cursor: pointer;

    &-start {
      width: 10px;
    }

    border: none;
    min-width: 110px;
    border-radius: 25px;
    height: 40px;
    color: #746EC0;
    background-color: #161338;

  }

  &-primary {
    border-radius: 47px;
    background: radial-gradient(76.37% 1209.82% at 82.18% 67.86%, #7F52FF 0%, #7B6DFF 68.75%);
    color: #ffffff;
    --bs-btn-border-color: none;
  }

  &-secondary {
    color: #000000;
    background-color: #ffffff;

    &:disabled {
      background-color: rgba(255, 255, 255, 0.6);
      color: #000000;
    }

    &-small {
      height: 28px;
      font-size: 0.75rem;
    }
  }

  &-tertiary {
    height: 36px;
    border: 1px solid #FFFFFF;
    border-radius: 43px;
    color: $white;
    width: 144px;
    font-size: 0.75rem;
    line-height: 0.9375rem;
    font-weight: 700;

    --bs-btn-border-color: #6c757d;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #5c636a;
    --bs-btn-hover-border-color: #565e64;
    --bs-btn-focus-shadow-rgb: 130, 138, 145;
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;

  }

  &-ptype {
    --bs-btn-disabled-opacity: 0.4;
  }

  &-tabs {
    padding: 0.1rem 1rem;
    border-radius: 1.5625rem;
    color: rgba(255, 255, 255, 0.79);
  
    a {
      text-decoration: none;
      color: #ffffff;
    }
  
    a:hover {
      text-decoration: underline;
    }
  
    &-active {
      background-color: hwb(0 16% 84% / 0.58);
      color: #ffffff;
      font-weight: 700;

      &-sort {
        border: solid 2px rgba(127, 82, 255, 1);
        font-weight: 700;
        color: #ffffff;
      }

    }    
  }


  &-login, &-signup {
    width: 170px;
    font-size: 1rem;
  }

  &-login, &-login:hover {
    background-color: #ffffff !important;
    color: #000000;
    font-weight: 700;
  }

  &-login-form {
    width: 100%;
  }
  
  &-signup {

  }
  &-signup:hover {
  }

  &-float-make-prediction {
    position: fixed;
    bottom: .5rem;
    right: .5rem;
    z-index: 100;
  }

  &-curved-side {
    border-radius: 1.5625rem;
  }

  &-light-purple,
  &-light-purple:hover {
    background-color: $light-purple;
  }  

  &-make-prediction {
    height: 50px;
    color: #ffffff;
    font-size: 1rem;
    line-height: 1.1875rem;
    font-weight: 700
  }

  &-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

}


@media screen and (max-width: 576px) {
  .btn {
    &-all-stats {
      max-width: unset;
    }
  }
}