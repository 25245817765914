.stock-detail {
  &-symbol {
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    color: #ffffff;
  }

  &-pct {
    font-size: 14px;
    line-height: 17px;
  }

}

.consensus-blurred {
  position: relative;

  &-image {
    width: 100%;
    max-width: 960px;

    img {
      width: 100%;
    }
  }

  &-message {
    position: absolute;

    width: 338px;
    margin: 0 auto;

    & .padlock-image {
      width: 32px;
      height: 39px;

      img {
        width: 100%;
      }
    }
    &-title {
      font-weight: 700;
    }
  }
}
