:root {
  --bs-secondary-bg: #1b1b1b;
}
html, body {
  min-height: 100%;
  // overflow: auto; * this breaks position: sticky for mobile header and search
}

.logo {
  width: 135px;

  img {
    width: 100%;
  }
}

.public-layout {

  &-container {
    max-width: 1000px;
    margin: 0 auto;
    // 100vh breaks window height calculation https://github.com/mozilla-mobile/fenix/issues/14839
    //min-height: 100vh;

    &-navigation {}

    &-content {
      width: 100%;
      //min-height: 100vh;
      background-color: #000000;
    }
  }

}

.footer-signup-login {

  border-top: solid 1px rgba(129, 129, 129, 1);
  border-bottom: solid 1px rgba(129, 129, 129, 1);
  background-color: #000000;
  z-index: 101; // to hide the floating Make Prediction button

  &-fixed {
    position: fixed;
    bottom: 0;
  }

}


.general-message {

  background: $off-black;
  border: 1px solid #343434;
  border-radius: 15px;
  padding: 1rem 1.5rem;

  &-head {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px
  }

  &-body {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }
}

a.contact-us {

  &:hover {
    color: $lighter-charcoal;
    text-decoration: none;
  }

}