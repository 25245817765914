@import './text';
@import './bg';
@import './border';

.border-primary {
  border: solid 1px $purple;
}

.border-white {
  border: solid 1px $white;
}

.card {
  border-radius: 0.9375rem;
}

.pill {
  border-radius: 2.1875rem;
}



.page-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 1rem;
}



.opacity-100 {
  opacity: 100% !important;
}

.opacity-70 {
  opacity: 70%;
}

.opacity-65 {
  opacity: 65%;
}

.opacity-60 {
  opacity: 60%;
}

.opacity-40 {
  opacity: 40%;
}

.opacity-10 {
  opacity: 10%;
}

@media screen and (max-width: 576px) {
  .text-sm-14 {
    font-size: 0.875rem !important;
  }
}