.user-stats {

  max-width: 30.4375rem;

    &-avatar {
      width: 2.8125rem;
      height: 2.8125rem;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    &-date-dropdown {
      min-width: 12.5rem;
    }

    &-items {
      // max-width: 22rem;
      width: 100%;
    }

}

.user-stat {
  &-card {
    border-radius: $size-15;
    background-color: $off-black;
    border: solid 1px $off-black;

    &-bar {
      border-top-left-radius: $size-15;
      border-bottom-left-radius: $size-15;

      &-progress {
        max-width: 17.3125rem;
      }
    }
    &-button {
      width: 2.5rem;
      text-align: center;
      padding: 0 0.25rem;
      height: 4.25rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: $size-12;
      border-bottom-right-radius: $size-12;
    }
  }
}


@media screen and (max-width: 576px) {

  .user-stats {
    max-width: unset;
    &-items {
      max-width: unset;
    }
  }

  .user-stat {
    &-card {
      &-bar {
        &-progress {
          max-width: unset;
        }
      }
    }
  }


}