.activity {

  &-row {
    border-bottom: solid 1px $darkest-gray;
  }
  &-avatar {
    width: 1.875rem;
    height: 1.875rem;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}