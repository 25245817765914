.trade-feed-filter {
  & .trader-type {
    min-width: 100px;
  }

  & .symbol-search {
    width: 180px;
  }

  & .security-type {
    width: 150px;
  }
}

@media screen and (max-width: 576px) {

  .trade-feed-filter {
    & .trader-type {
      min-width: unset;
      width: 145px;
      text-wrap: nowrap;
    }
  
    & .symbol-search {
      width: unset;
    }
  
    & .security-type {
      width: unset;
      min-width: 80px;
    }
  }
  

}