.strategy-profit {
  & .form-control {
    font-size: $size-12 !important;
    background-color: $white !important;
    color: $black !important;

  }

  & .form-group, & .form-group label {
    font-size: $size-12 !important;
    font-weight: bold !important;
  }

  & .form-group input.form-control {
    padding: 5px;
  }

  & .react-datepicker__input-container input {
    font-size: $size-12 !important;
  }

  & table tr th {
    font-size: $size-12 !important;
  }

  & table tr td {
    padding: 0.20rem;
  }
}