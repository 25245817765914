.bg-fire {
  background: linear-gradient(180.41deg, #FF472E 0.35%, #FF922E 124.48%);
}

.bg-primary {
  background: $dark-purple !important;
}

.bg-lavender {
  background-color: $lavender !important;
}

.bg-gradient-lavender {
  background: linear-gradient(253.12deg, rgba(127, 87, 255, 0.4) -54.26%, rgba(48, 38, 81, 0.4) 61.98%);
}

.bg-green {
  background: linear-gradient(236.25deg, rgba(46, 133, 39, 0.3234) -102.33%, rgba(148, 255, 139, 0.3388) 142.75%);
}

.bg-maroon {
  background: linear-gradient(236.25deg, rgba(133, 39, 39, 0.3234) -102.33%, rgba(255, 139, 139, 0.3388) 142.75%);
}

.bg-bright-green {
  background: linear-gradient(236.25deg, rgba(20, 255, 0, 0.3234) -102.33%, rgba(76, 255, 61, 0.3388) 142.75%);
}

.bg-mint-green {
  background-color: $mint-green !important;
}

.bg-crimson {
  background: linear-gradient(236.25deg, rgba(245, 0, 0, 0.3234) -102.33%, rgba(255, 39, 39, 0.3388) 142.75%);  
}

.bg-gradient-blue {
  background: linear-gradient(87.29deg, #232042 21.21%, rgba(82, 0, 255, 0.97) 208.81%);
}

.bg-gradient-purple {
  background: linear-gradient(87.29deg, #392042 21.21%, rgba(82, 0, 255, 0.97) 208.81%);
}

.bg-gradient-dark-blue {
  background: linear-gradient(87.29deg, rgba(35, 32, 66, 0.3) 21.21%, rgba(82, 0, 255, 0.291) 208.81%);
}

.bg-gradient-dark-purple {
  background: linear-gradient(87.29deg, rgba(57, 32, 66, 0.3) 21.21%, rgba(82, 0, 255, 0.291) 208.81%);
}

.bg-gradient-platinum {
  background: $gradient-platinum;
}

.bg-medium-purple {
  background-color: $medium-purple !important;
}

.bg-yellow {
  background-color: $yellow !important;
}

.bg-red {
  background-color: $red !important;
}

.bg-purple {
  background-color: $purple; 
}

.bg-charcoal {
  &.no-hover:hover {
    background-color: $charcoal !important;
  }
  background-color: $charcoal;
}

.bg-light-charcoal {
  background-color: $light-charcoal;
}

.bg-charcoal:hover {
  background-color: $light-charcoal;
}

.bg-off-white {
  background-color: $off-white;
}

.bg-darkest-gray {
  background-color: $darkest-gray;
  &.no-hover:hover {
    background-color: $darkest-gray !important;
  }
}

.bg-off-black {
  background-color: $off-black;
  &.no-hover:hover {
    background-color: $off-black !important;
  }
  &:hover {
    background-color: $darkest-gray !important;
  }
}

.bg-transparent-light {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.bg-transparent-dark {
  background-color: rgba(17, 17, 17, 0.25);
}

.bg-dark-gray {
  background-color: $dark-gray;
}

.bg-hover-off-black {
  &:hover {
    background-color: $off-black;
  }
}

.bg-hover-darkest-gray {
  &:hover {
    background-color: $darkest-gray;
  }
}

.bg-rank-1 {
  background-color: $bronze;
}

.bg-rank-2 {
  background-color: $silver;
}

.bg-rank-3 {
  background-color: $gold;
}

.bg-rank-4 {
  background-color: $platinum;
}

.bg-rank-5 {
  background-color: $master;
}

.bg-lighter-charcoal {
  background-color: $lighter-charcoal
}

.bg-black-to-green {
  background: linear-gradient(180deg, #000000 78.28%, #4FF4FF 223.31%);
}

.bg-black-to-purple {
  background: linear-gradient(180deg, #000000 35.52%, #7111D1 223.31%);
}

.bg-hover-white {
  &:hover {
    background-color: #ffffff !important;
  }
}